import logo from './assets/img/logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <a
          className="App-link"
          href="https://instagram.com/pintapuntos.gt"
          target="_blank"
          rel="noopener noreferrer"
        >
          @pintapuntos.gt
        </a>
        <a
          className="App-link"
          href="https://wa.me/50259859901"
          target="_blank"
          rel="noopener noreferrer"
        >
          +502 5985-9901
        </a>
      </header>
    </div>
  );
}

export default App;
